import AppProvider from 'providers/AppProvider';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';
import BreakpointsProvider from 'providers/BreakpointsProvider';
// import SettingsPanelProvider from 'providers/SettingsPanelProvider';
import { RouterProvider } from 'react-router-dom';
import { router } from 'Routes';
// import ChatWidgetProvider from 'providers/ChatWidgetProvider';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <StrictMode>
    <AppProvider>
      {/* <SettingsPanelProvider> */}
      {/* <ChatWidgetProvider> */}
      <BreakpointsProvider>
        <RouterProvider router={router} />
      </BreakpointsProvider>
      {/* </ChatWidgetProvider> */}
      {/* </SettingsPanelProvider> */}
    </AppProvider>
  </StrictMode>
);

serviceWorkerRegistration.register();
