// import MainLayout from 'layouts/MainLayout';
import { Navigate, RouteObject, createBrowserRouter } from 'react-router-dom';
// import MainLayoutProvider from 'providers/MainLayoutProvider';

import App from 'App';
import ComingSoon from 'pages/ComingSoon';

const routes: RouteObject[] = [
  {
    element: <App />,
    children: [
      {
        path: '/',
        // element: (
        //   <MainLayoutProvider>
        //     <MainLayout />
        //   </MainLayoutProvider>
        // ),
        children: [
          {
            index: true,
            element: <ComingSoon />
          }
        ]
      },
      {
        path: '*',
        element: <Navigate to="/" replace />
      }
    ]
  }
];
export const router = createBrowserRouter(routes);

export default routes;
